<template>
  <div v-if="!isLoaded" style="min-height: 200px" class="d-flex flex-row align-center">
    <v-progress-linear indeterminate></v-progress-linear>
  </div>
  <div v-else class="konvertRegistration">
    <router-link :to="{ path: '/main-2024/applicants' }" style="width: 100px">
      <v-btn class="router_btn">&#8592;</v-btn>
    </router-link>
    <h2 class="konvertRegistration__header" style="margin-right: 19px">Регистрация конвертов</h2>
    <div class="infoRow">
      <div class="infoDiv" style="width: 845px; margin-right: 25px">
        <h3 class="infoDiv__header">
          Подведомственные Минобрнауки [
          <span style="color: #1f8b24">{{ minobrAcceptedSum }}</span> /
          <span style="color: #dc2627">{{ minobrCanceledSum }}</span> ]
        </h3>
        <div class="d-flex flex-row">
          <div class="stpBlock">
            <div class="d-flex flex-row">
              <img class="stpBlock__img" src="@/assets/icons/cup-dark.svg"/>
              <span class="stpBlock__name" style="width: 111px">Стипендия Президента РФ</span>
            </div>
            <div class="d-flex flex-row" style="margin-top: 11px">
              <span class="stpBlock__row">Студенты</span>
              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg"/>
              <span
                class="konvert__accepted-num"
                v-html="konvertStatistics.minobr[0].acceptedStud"></span>
              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg"/>
              <span
                class="konvert__declined-num"
                v-html="konvertStatistics.minobr[0].canceledStud"></span>
            </div>
            <!--            <div class="d-flex flex-row" style="margin-top: 6px">-->
            <!--              <span class="stpBlock__row" style="margin-right: 16px">Аспиранты</span>-->
            <!--              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg" />-->
            <!--              <span-->
            <!--                class="konvert__accepted-num"-->
            <!--                v-html="konvertStatistics.minobr[0].acceptedAsp"></span>-->
            <!--              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg" />-->
            <!--              <span-->
            <!--                class="konvert__declined-num"-->
            <!--                v-html="konvertStatistics.minobr[0].canceledAsp"></span>-->
            <!--            </div>-->
          </div>
          <div class="stpBlock" style="padding: 16px 56px 24px 34px; width: 300px">
            <div class="d-flex flex-row">
              <img class="stpBlock__img" src="@/assets/icons/cup-dark.svg"/>
              <span class="stpBlock__name" style="width: 134px">Стипендия Правительства РФ</span>
            </div>
            <div class="d-flex flex-row" style="margin-top: 11px">
              <span class="stpBlock__row">Студенты</span>
              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg"/>
              <span
                class="konvert__accepted-num"
                v-html="konvertStatistics.minobr[1].acceptedStud"></span>
              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg"/>
              <span
                class="konvert__declined-num"
                v-html="konvertStatistics.minobr[1].canceledStud"></span>
            </div>
            <!--            <div class="d-flex flex-row" style="margin-top: 6px">-->
            <!--              <span class="stpBlock__row" style="margin-right: 16px">Аспиранты</span>-->
            <!--              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg" />-->
            <!--              <span-->
            <!--                class="konvert__accepted-num"-->
            <!--                v-html="konvertStatistics.minobr[1].acceptedAsp"></span>-->
            <!--              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg" />-->
            <!--              <span-->
            <!--                class="konvert__declined-num"-->
            <!--                v-html="konvertStatistics.minobr[1].canceledAsp"></span>-->
            <!--            </div>-->
          </div>
          <div
            class="stpBlock"
            style="padding: 16px 24px 24px 27px; width: 261px; border-right: none">
            <div class="d-flex flex-row">
              <img class="stpBlock__img" src="@/assets/icons/cup-dark.svg"/>
              <span class="stpBlock__name" style="width: 170px"
              >Стипендии Президента и Правительства РФ</span
              >
            </div>
            <div class="d-flex flex-row" style="margin-top: 11px">
              <span class="stpBlock__row">Студенты</span>
              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg"/>
              <span
                class="konvert__accepted-num"
                v-html="konvertStatistics.minobr[2].acceptedStud"></span>
              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg"/>
              <span
                class="konvert__declined-num"
                v-html="konvertStatistics.minobr[2].canceledStud"></span>
            </div>
            <!--            <div class="d-flex flex-row" style="margin-top: 6px">-->
            <!--              <span class="stpBlock__row" style="margin-right: 16px">Аспиранты</span>-->
            <!--              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg" />-->
            <!--              <span-->
            <!--                class="konvert__accepted-num"-->
            <!--                v-html="konvertStatistics.minobr[2].acceptedAsp"></span>-->
            <!--              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg" />-->
            <!--              <span-->
            <!--                class="konvert__declined-num"-->
            <!--                v-html="konvertStatistics.minobr[2].canceledAsp"></span>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="infoDiv" style="margin-right: 25px;">
        <h3 class="infoDiv__header">
          Правительственные [ <span style="color: #1f8b24">{{ pravitAcceptedSum }}</span> /
          <span style="color: #dc2627">{{ pravitCanceledSum }}</span> ]
        </h3>
        <div class="d-flex flex-row">
          <div
            class="stpBlock"
            style="padding: 16px 93px 24px 23px; width: 326px; border-right: none">
            <div class="d-flex flex-row">
              <img class="stpBlock__img" src="@/assets/icons/cup-dark.svg"/>
              <span class="stpBlock__name" style="width: 134px">Стипендия Правительства РФ</span>
            </div>
            <div class="d-flex flex-row" style="margin-top: 11px">
              <span class="stpBlock__row">Студенты</span>
              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg"/>
              <span
                class="konvert__accepted-num"
                v-html="konvertStatistics.pravit[0].acceptedStud"></span>
              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg"/>
              <span
                class="konvert__declined-num"
                v-html="konvertStatistics.pravit[0].canceledStud"></span>
            </div>
            <!--            <div class="d-flex flex-row" style="margin-top: 6px">-->
            <!--              <span class="stpBlock__row" style="margin-right: 16px">Аспиранты</span>-->
            <!--              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg" />-->
            <!--              <span-->
            <!--                class="konvert__accepted-num"-->
            <!--                v-html="konvertStatistics.pravit[0].acceptedAsp"></span>-->
            <!--              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg" />-->
            <!--              <span-->
            <!--                class="konvert__declined-num"-->
            <!--                v-html="konvertStatistics.pravit[0].canceledAsp"></span>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="infoDiv">
        <h3 class="infoDiv__header">
          Негосударственные [ <span style="color: #1f8b24">{{ negosAcceptedSum }}</span> /
          <span style="color: #dc2627">{{ negosCanceledSum }}</span> ]
        </h3>
        <div class="d-flex flex-row">
          <div
            class="stpBlock"
            style="padding: 16px 93px 24px 23px; width: 326px; border-right: none">
            <div class="d-flex flex-row">
              <img class="stpBlock__img" src="@/assets/icons/cup-dark.svg"/>
              <span class="stpBlock__name" style="width: 134px">Стипендия Президента РФ</span>
            </div>
            <div class="d-flex flex-row" style="margin-top: 11px">
              <span class="stpBlock__row">Студенты</span>
              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg"/>
              <span
                class="konvert__accepted-num"
                v-html="konvertStatistics.negos[0].acceptedStud"></span>
              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg"/>
              <span
                class="konvert__declined-num"
                v-html="konvertStatistics.negos[0].canceledStud"></span>
            </div>
            <!--            <div class="d-flex flex-row" style="margin-top: 6px">-->
            <!--              <span class="stpBlock__row" style="margin-right: 16px">Аспиранты</span>-->
            <!--              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg" />-->
            <!--              <span-->
            <!--                class="konvert__accepted-num"-->
            <!--                v-html="konvertStatistics.negos[0].acceptedAsp"></span>-->
            <!--              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg" />-->
            <!--              <span-->
            <!--                class="konvert__declined-num"-->
            <!--                v-html="konvertStatistics.negos[0].canceledAsp"></span>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="infoRow">-->
    <!--      <div class="infoDiv" style="width: 845px; margin-right: 25px">-->
    <!--        <h3 class="infoDiv__header">-->
    <!--          Новые регионы [ <span style="color: #1f8b24">{{ newRegionsAcceptedSum }}</span> /-->
    <!--          <span style="color: #dc2627">{{ newRegionsCanceledSum }}</span> ]-->
    <!--        </h3>-->
    <!--        <div class="d-flex flex-row">-->
    <!--          <div class="stpBlock">-->
    <!--            <div class="d-flex flex-row">-->
    <!--              <img class="stpBlock__img" src="@/assets/icons/cup-dark.svg"/>-->
    <!--              <span class="stpBlock__name" style="width: 111px">Стипендия Президента РФ</span>-->
    <!--            </div>-->
    <!--            <div class="d-flex flex-row" style="margin-top: 11px">-->
    <!--              <span class="stpBlock__row">Студенты</span>-->
    <!--              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg"/>-->
    <!--              <span-->
    <!--                class="konvert__accepted-num"-->
    <!--                v-html="konvertStatistics.newRegions[0].acceptedStud"></span>-->
    <!--              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg"/>-->
    <!--              <span-->
    <!--                class="konvert__declined-num"-->
    <!--                v-html="konvertStatistics.newRegions[0].canceledStud"></span>-->
    <!--            </div>-->
    <!--            &lt;!&ndash;            <div class="d-flex flex-row" style="margin-top: 6px">&ndash;&gt;-->
    <!--            &lt;!&ndash;              <span class="stpBlock__row" style="margin-right: 16px">Аспиранты</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg" />&ndash;&gt;-->
    <!--            &lt;!&ndash;              <span&ndash;&gt;-->
    <!--            &lt;!&ndash;                class="konvert__accepted-num"&ndash;&gt;-->
    <!--            &lt;!&ndash;                v-html="konvertStatistics.newRegions[0].acceptedAsp"></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg" />&ndash;&gt;-->
    <!--            &lt;!&ndash;              <span&ndash;&gt;-->
    <!--            &lt;!&ndash;                class="konvert__declined-num"&ndash;&gt;-->
    <!--            &lt;!&ndash;                v-html="konvertStatistics.newRegions[0].canceledAsp"></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--          </div>-->
    <!--          <div class="stpBlock" style="padding: 16px 56px 24px 34px; width: 300px">-->
    <!--            <div class="d-flex flex-row">-->
    <!--              <img class="stpBlock__img" src="@/assets/icons/cup-dark.svg"/>-->
    <!--              <span class="stpBlock__name" style="width: 134px">Стипендия Правительства РФ</span>-->
    <!--            </div>-->
    <!--            <div class="d-flex flex-row" style="margin-top: 11px">-->
    <!--              <span class="stpBlock__row">Студенты</span>-->
    <!--              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg"/>-->
    <!--              <span-->
    <!--                class="konvert__accepted-num"-->
    <!--                v-html="konvertStatistics.newRegions[1].acceptedStud"></span>-->
    <!--              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg"/>-->
    <!--              <span-->
    <!--                class="konvert__declined-num"-->
    <!--                v-html="konvertStatistics.newRegions[1].canceledStud"></span>-->
    <!--            </div>-->
    <!--            &lt;!&ndash;            <div class="d-flex flex-row" style="margin-top: 6px">&ndash;&gt;-->
    <!--            &lt;!&ndash;              <span class="stpBlock__row" style="margin-right: 16px">Аспиранты</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg" />&ndash;&gt;-->
    <!--            &lt;!&ndash;              <span&ndash;&gt;-->
    <!--            &lt;!&ndash;                class="konvert__accepted-num"&ndash;&gt;-->
    <!--            &lt;!&ndash;                v-html="konvertStatistics.newRegions[1].acceptedAsp"></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg" />&ndash;&gt;-->
    <!--            &lt;!&ndash;              <span&ndash;&gt;-->
    <!--            &lt;!&ndash;                class="konvert__declined-num"&ndash;&gt;-->
    <!--            &lt;!&ndash;                v-html="konvertStatistics.newRegions[1].canceledAsp"></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--          </div>-->
    <!--          <div-->
    <!--            class="stpBlock"-->
    <!--            style="padding: 16px 24px 24px 27px; width: 261px; border-right: none">-->
    <!--            <div class="d-flex flex-row">-->
    <!--              <img class="stpBlock__img" src="@/assets/icons/cup-dark.svg"/>-->
    <!--              <span class="stpBlock__name" style="width: 170px"-->
    <!--              >Стипендии Президента и Правительства РФ</span-->
    <!--              >-->
    <!--            </div>-->
    <!--            <div class="d-flex flex-row" style="margin-top: 11px">-->
    <!--              <span class="stpBlock__row">Студенты</span>-->
    <!--              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg"/>-->
    <!--              <span-->
    <!--                class="konvert__accepted-num"-->
    <!--                v-html="konvertStatistics.newRegions[2].acceptedStud"></span>-->
    <!--              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg"/>-->
    <!--              <span-->
    <!--                class="konvert__declined-num"-->
    <!--                v-html="konvertStatistics.newRegions[2].canceledStud"></span>-->
    <!--            </div>-->
    <!--            &lt;!&ndash;            <div class="d-flex flex-row" style="margin-top: 6px">&ndash;&gt;-->
    <!--            &lt;!&ndash;              <span class="stpBlock__row" style="margin-right: 16px">Аспиранты</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <img class="konvert__accepted" src="@/assets/icons/konvert-green.svg" />&ndash;&gt;-->
    <!--            &lt;!&ndash;              <span&ndash;&gt;-->
    <!--            &lt;!&ndash;                class="konvert__accepted-num"&ndash;&gt;-->
    <!--            &lt;!&ndash;                v-html="konvertStatistics.newRegions[2].acceptedAsp"></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <img class="konvert__declined" src="@/assets/icons/konvert-red.svg" />&ndash;&gt;-->
    <!--            &lt;!&ndash;              <span&ndash;&gt;-->
    <!--            &lt;!&ndash;                class="konvert__declined-num"&ndash;&gt;-->
    <!--            &lt;!&ndash;                v-html="konvertStatistics.newRegions[2].canceledAsp"></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <v-text-field
      v-model="searchFio"
      label="Начните набирать фамилию..."
      style="max-width: 400px; margin-top: 25px; margin-bottom: 19px"/>
    <v-dialog v-model="regConfirmDialog" width="870">
      <v-card>
        <v-card-title
        >Подтвердите действие: претендент не заблокирован организацией,
          заблокировать?
        </v-card-title
        >
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="acceptKonvertRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="regConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <GridTable
      :data-rows="showingData"
      :headers="tableHeaders.orgMassive"
      :headers-for-rows="tableHeaders.orgMassive"
      :num-cols="tableHeaders.countCells"
      :loading-data="loading"
      :borderTop="'none'"
      style="background: #ffffff; box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06); border-radius: 4px">
      <template #num="{ row }">
        <span>{{ row.rowOrder }}</span>
        <div class="regKonvert__blocked" v-if="row.blockFlBlock"></div>
        <div class="regKonvert__opened" v-else></div>
      </template>
      <template #orgInfo="{ row }">
        <span @click="clickOnRow(row)">{{ row.org.owner.name }}</span>
        <!--        <span class="orgId">{{ row.org.id }}</span>-->
      </template>
      <template #ugrulUstav="{ row }">
        <div style="display: flex; flex-direction: column; width: 67px">
          <div style="display: flex; flex-direction: row; justify-content: space-between">
            <img src="@/assets/icons/check-true.svg" v-if="row.egrul"/>
            <img src="@/assets/icons/check-false.svg" style="padding: 0 1px; width: 14px" v-else/>
            <span>ЕГРЮЛ</span>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between">
            <img src="@/assets/icons/check-true.svg" v-if="row.ustav"/>
            <img src="@/assets/icons/check-false.svg" style="padding: 0 1px; width: 14px" v-else/>
            <span style="margin-right: 9px">Устав</span>
          </div>
        </div>
      </template>
      <template #fio="{ row }">
        <div style="display: flex; flex-direction: column; margin-left: 16px">
          <span>{{ row.mainLastName }}</span>
          <span>{{ `${row.mainFirstName} ${row.mainSecondName}` }}</span>
          <div class="educInfo">
            <span>{{ `${row.mainSpec.usl.name} • ${row.mainFo.name}` }}</span>
            <span>{{ row.mainCourse }} курс</span>
          </div>
        </div>
      </template>
      <template #stpType="{ row }">
        <div style="display: flex; flex-direction: row; align-items: center">
          <img src="@/assets/icons/cup-grey.svg" style="margin-right: 10px"/>
          <span>{{ row.mainStpType.name }}</span>
        </div>
      </template>
      <template #konvert="{ row }">
        <div v-if="row.konvertConvert">
          <img src="@/assets/icons/konvert.svg" style="margin-right: 10px; align-self: baseline"/>
          <span>{{ row.konvertConvert }}</span>
          <div style="display: flex; flex-direction: row; margin-top: 10px; align-items: center">
            <div @click="cancelKonvertRow(row)" class="cancelKonvert"></div>
            <span class="konvertDate" v-if="row.konvertConvertDate">
              {{ dateFormat(row.konvertConvertDate.slice(0, 10)) }}
            </span>
          </div>
        </div>
        <div v-else>
          <img src="@/assets/icons/konvert.svg" style="margin-right: 10px; align-self: baseline"/>
          <span @click="regConfirmDialogFunc(row)" class="acceptKonvert">Регистрация</span>
        </div>
      </template>
      <template #noScanList="{ row }">
        <div v-if="row.noScanList">
          <span v-if="row.noScanList.length > 0">Отсутствуют сканы:</span>
          <div
            v-for="(item, j) in row.noScanList"
            :key="j"
            style="display: flex; flex-direction: row; margin-top: 4px">
            <img src="@/assets/icons/check-false.svg" style="margin-right: 10px"/>
            <span>{{ item }}</span>
          </div>
        </div>
      </template>
    </GridTable>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import {GridTable} from '@frontenddevelopers/ined-components/src/lib';
import KonvertRegistrationJson from '@/2024/components/applicants/admin/main/konverts/KonvertRegistrationJson';

export default {
  name: 'KonvertRegistration',
  components: {GridTable},
  data() {
    return {
      tableHeaders: [],
      applicantsArray: [],
      konvertStatistics: {},
      regConfirmDialog: false,
      regConfirmRow: null,
      loading: false,
      searchFio: '',
    };
  },

  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getFakeUser: 'getFakeUser',
    }),

    showingData() {
      return this.applicantsArray.filter(
        (el) =>
          !this.searchFio ||
          `${el.mainLastName} ${el.mainFirstName} ${el.mainSecondName}`
            .toLowerCase()
            .search(this.searchFio.toLowerCase()) !== -1,
      );
    },

    newRegionsAcceptedSum() {
      if (this.konvertStatistics.newRegions) {
        return (
          this.konvertStatistics.newRegions[0].acceptedAsp +
          this.konvertStatistics.newRegions[0].acceptedStud +
          this.konvertStatistics.newRegions[1].acceptedAsp +
          this.konvertStatistics.newRegions[1].acceptedStud +
          this.konvertStatistics.newRegions[2].acceptedAsp +
          this.konvertStatistics.newRegions[2].acceptedStud
        );
      } else {
        return null;
      }
    },

    newRegionsCanceledSum() {
      if (this.konvertStatistics.newRegions) {
        return (
          this.konvertStatistics.newRegions[0].canceledAsp +
          this.konvertStatistics.newRegions[0].canceledStud +
          this.konvertStatistics.newRegions[1].canceledAsp +
          this.konvertStatistics.newRegions[1].canceledStud +
          this.konvertStatistics.newRegions[2].canceledAsp +
          this.konvertStatistics.newRegions[2].canceledStud
        );
      } else {
        return null;
      }
    },

    minobrAcceptedSum() {
      if (this.konvertStatistics.minobr) {
        return (
          this.konvertStatistics.minobr[0].acceptedAsp +
          this.konvertStatistics.minobr[0].acceptedStud +
          this.konvertStatistics.minobr[1].acceptedAsp +
          this.konvertStatistics.minobr[1].acceptedStud +
          this.konvertStatistics.minobr[2].acceptedAsp +
          this.konvertStatistics.minobr[2].acceptedStud
        );
      } else {
        return null;
      }
    },

    minobrCanceledSum() {
      if (this.konvertStatistics.minobr) {
        return (
          this.konvertStatistics.minobr[0].canceledAsp +
          this.konvertStatistics.minobr[0].canceledStud +
          this.konvertStatistics.minobr[1].canceledAsp +
          this.konvertStatistics.minobr[1].canceledStud +
          this.konvertStatistics.minobr[2].canceledAsp +
          this.konvertStatistics.minobr[2].canceledStud
        );
      } else {
        return null;
      }
    },

    negosAcceptedSum() {
      if (this.konvertStatistics.negos) {
        return (
          this.konvertStatistics.negos[0].acceptedAsp +
          this.konvertStatistics.negos[0].acceptedStud
        );
      } else {
        return null;
      }
    },

    negosCanceledSum() {
      if (this.konvertStatistics.negos) {
        return (
          this.konvertStatistics.negos[0].canceledAsp +
          this.konvertStatistics.negos[0].canceledStud
        );
      } else {
        return null;
      }
    },

    pravitAcceptedSum() {
      if (this.konvertStatistics.pravit) {
        return (
          this.konvertStatistics.pravit[0].acceptedAsp +
          this.konvertStatistics.pravit[0].acceptedStud
        );
      } else {
        return null;
      }
    },

    pravitCanceledSum() {
      if (this.konvertStatistics.pravit) {
        return (
          this.konvertStatistics.pravit[0].canceledAsp +
          this.konvertStatistics.pravit[0].canceledStud
        );
      } else {
        return null;
      }
    },
    isLoaded() {
      if (this.minobrAcceptedSum || this.minobrAcceptedSum == 0) {
        return true;
      }
      if (this.minobrCanceledSum || this.minobrCanceledSum == 0) {
        return true;
      }
      if (this.newRegionsAcceptedSum || this.newRegionsAcceptedSum == 0) {
        return true;
      }
      if (this.newRegionsCanceledSum || this.newRegionsCanceledSum == 0) {
        return true;
      }
      if (this.negosAcceptedSum || this.negosAcceptedSum == 0) {
        return true;
      }
      if (this.negosCanceledSum || this.negosCanceledSum == 0) {
        return true;
      }
      if (this.pravitAcceptedSum || this.pravitAcceptedSum == 0) {
        return true;
      }
      if (this.pravitCanceledSum || this.pravitCanceledSum == 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    this.getAllKonvertList();
    this.getAllKonvertStatistics();
    this.tableHeaders = KonvertRegistrationJson(this);
  },

  methods: {
    ...mapActions('keycloak', {initFakeUser: 'initFakeUser'}),

    async getAllKonvertList() {
      this.loading = true;
      let uri = `/api/2024/main/getAllKonvertList`;
      try {
        const res = await axios.get(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.applicantsArray = res.data;
          for (let a = 0; a < this.applicantsArray.length; a++) {
            this.applicantsArray[a].rowOrder = a + 1;
          }
          this.loading = false;
          console.log('getAllKonvertList успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async getAllKonvertStatistics() {
      this.loading = true;
      let uri = `/api/2024/main/getAllKonvertStatistics`;
      try {
        const res = await axios.get(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.konvertStatistics = res.data;
          this.loading = false;
          console.log('getAllKonvertStatistics успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    dateFormat(arg) {
      let x = arg.replace(/-/g, '.');
      let years = x.slice(0, 4);
      let days = x.slice(-2);
      let months = x.slice(4, -2);
      return `${days}${months}${years}`;
    },

    async regConfirmDialogFunc(row) {
      this.regConfirmRow = row.id;
      if (row.blockFlBlock) {
        await this.acceptKonvertRow();
      } else {
        this.regConfirmDialog = true;
      }
    },

    async acceptKonvertRow() {
      try {
        const res = await axios.put(
          `/api/2024/main/convert/accept?rowId=${this.regConfirmRow}`,
          '',
          {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}},
        );
        if (res.status === 200 && res.data) {
          console.log(res.data);
          const x = this.applicantsArray.find((el) => el.id === this.regConfirmRow);
          x.konvertConvert = res.data.konvertConvert;
          x.konvertConvertDate = res.data.konvertConvertDate;
          this.regConfirmRow = null;
          this.regConfirmDialog = false;
          // await this.getAllKonvertStatistics();
          if (res.data.mainSpec.usl.id === 6) {
            // аспирантуроа
            if (res.data.mainOrg.orgFounder.id === 9) {
              // минобрнауки
              if (res.data.mainStpType.id === 1) {
                // президента
                this.konvertStatistics.minobr[0].acceptedAsp++;
                this.konvertStatistics.minobr[0].canceledAsp--;
              } else if (res.data.mainStpType.id === 2) {
                // правительства
                this.konvertStatistics.minobr[1].acceptedAsp++;
                this.konvertStatistics.minobr[1].canceledAsp--;
              } else if (res.data.mainStpType.id === 3) {
                // президента и правительства
                this.konvertStatistics.minobr[2].acceptedAsp++;
                this.konvertStatistics.minobr[2].canceledAsp--;
              }
            } else if (res.data.mainOrg.orgFounder.id === 20) {
              //правительственные
              this.konvertStatistics.pravit[0].acceptedAsp++;
              this.konvertStatistics.pravit[0].canceledAsp--;
            } else if (res.data.mainOrg.orgFounder.id === 52) {
              //негосударственные
              this.konvertStatistics.negos[0].acceptedAsp++;
              this.konvertStatistics.negos[0].canceledAsp--;
            } else if (res.data.mainOrg.orgFounder.id === 171) {
              //новые регионы
              if (res.data.mainStpType.id === 1) {
                // президента
                this.konvertStatistics.newRegions[0].acceptedAsp++;
                this.konvertStatistics.newRegions[0].canceledAsp--;
              } else if (res.data.mainStpType.id === 2) {
                // правительства
                this.konvertStatistics.newRegions[1].acceptedAsp++;
                this.konvertStatistics.newRegions[1].canceledAsp--;
              } else if (res.data.mainStpType.id === 3) {
                // президента и правительства
                this.konvertStatistics.newRegions[2].acceptedAsp++;
                this.konvertStatistics.newRegions[2].canceledAsp--;
              }
            }
          } else {
            if (res.data.mainOrg.orgFounder.id === 9) {
              // минобрнауки
              if (res.data.mainStpType.id === 1) {
                // президента
                this.konvertStatistics.minobr[0].acceptedStud++;
                this.konvertStatistics.minobr[0].canceledStud--;
              } else if (res.data.mainStpType.id === 2) {
                // правительства
                this.konvertStatistics.minobr[1].acceptedStud++;
                this.konvertStatistics.minobr[1].canceledStud--;
              } else if (res.data.mainStpType.id === 3) {
                // президента и правительства
                this.konvertStatistics.minobr[2].acceptedStud++;
                this.konvertStatistics.minobr[2].canceledStud--;
              }
            } else if (res.data.mainOrg.orgFounder.id === 20) {
              //правительственные
              this.konvertStatistics.pravit[0].acceptedStud++;
              this.konvertStatistics.pravit[0].canceledStud--;
            } else if (res.data.mainOrg.orgFounder.id === 52) {
              //негосударственные
              this.konvertStatistics.negos[0].acceptedStud++;
              this.konvertStatistics.negos[0].canceledStud--;
            } else if (res.data.mainOrg.orgFounder.id === 171) {
              //новые регионы
              if (res.data.mainStpType.id === 1) {
                // президента
                this.konvertStatistics.newRegions[0].acceptedStud++;
                this.konvertStatistics.newRegions[0].canceledStud--;
              } else if (res.data.mainStpType.id === 2) {
                // правительства
                this.konvertStatistics.newRegions[1].acceptedStud++;
                this.konvertStatistics.newRegions[1].canceledStud--;
              } else if (res.data.mainStpType.id === 3) {
                // президента и правительства
                this.konvertStatistics.newRegions[2].acceptedStud++;
                this.konvertStatistics.newRegions[2].canceledStud--;
              }
            }
          }
          console.log('blockRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async cancelKonvertRow(row) {
      try {
        const res = await axios.put(`/api/2024/main/convert/cancel?rowId=${row.id}`, '', {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          console.log(res.data);
          const x = this.applicantsArray.find((el) => el.id === row.id);
          x.konvertConvert = null;
          x.konvertConvertDate = null;
          // await this.getAllKonvertStatistics();
          if (res.data.mainSpec.usl.id === 6) {
            // аспирантуроа
            if (res.data.mainOrg.orgFounder.id === 9) {
              // минобрнауки
              if (res.data.mainStpType.id === 1) {
                // президента
                this.konvertStatistics.minobr[0].acceptedAsp--;
                this.konvertStatistics.minobr[0].canceledAsp++;
              } else if (res.data.mainStpType.id === 2) {
                // правительства
                this.konvertStatistics.minobr[1].acceptedAsp--;
                this.konvertStatistics.minobr[1].canceledAsp++;
              } else if (res.data.mainStpType.id === 3) {
                // президента и правительства
                this.konvertStatistics.minobr[2].acceptedAsp--;
                this.konvertStatistics.minobr[2].canceledAsp++;
              }
            } else if (res.data.mainOrg.orgFounder.id === 20) {
              //правительственные
              this.konvertStatistics.pravit[0].acceptedAsp--;
              this.konvertStatistics.pravit[0].canceledAsp++;
            } else if (res.data.mainOrg.orgFounder.id === 52) {
              //негосударственные
              this.konvertStatistics.negos[0].acceptedAsp--;
              this.konvertStatistics.negos[0].canceledAsp++;
            } else if (res.data.mainOrg.orgFounder.id === 171) {
              //новые регионы
              if (res.data.mainStpType.id === 1) {
                // президента
                this.konvertStatistics.newRegions[0].acceptedAsp--;
                this.konvertStatistics.newRegions[0].canceledAsp++;
              } else if (res.data.mainStpType.id === 2) {
                // правительства
                this.konvertStatistics.newRegions[1].acceptedAsp--;
                this.konvertStatistics.newRegions[1].canceledAsp++;
              } else if (res.data.mainStpType.id === 3) {
                // президента и правительства
                this.konvertStatistics.newRegions[2].acceptedAsp--;
                this.konvertStatistics.newRegions[2].canceledAsp++;
              }
            }
          } else {
            if (res.data.mainOrg.orgFounder.id === 9) {
              // минобрнауки
              if (res.data.mainStpType.id === 1) {
                // президента
                this.konvertStatistics.minobr[0].acceptedStud--;
                this.konvertStatistics.minobr[0].canceledStud++;
              } else if (res.data.mainStpType.id === 2) {
                // правительства
                this.konvertStatistics.minobr[1].acceptedStud--;
                this.konvertStatistics.minobr[1].canceledStud++;
              } else if (res.data.mainStpType.id === 3) {
                // президента и правительства
                this.konvertStatistics.minobr[2].acceptedStud--;
                this.konvertStatistics.minobr[2].canceledStud++;
              }
            } else if (res.data.mainOrg.orgFounder.id === 20) {
              //правительственные
              this.konvertStatistics.pravit[0].acceptedStud--;
              this.konvertStatistics.pravit[0].canceledStud++;
            } else if (res.data.mainOrg.orgFounder.id === 52) {
              //негосударственные
              this.konvertStatistics.negos[0].acceptedStud--;
              this.konvertStatistics.negos[0].canceledStud++;
            } else if (res.data.mainOrg.orgFounder.id === 171) {
              //новые регионы
              if (res.data.mainStpType.id === 1) {
                // президента
                this.konvertStatistics.newRegions[0].acceptedStud--;
                this.konvertStatistics.newRegions[0].canceledStud++;
              } else if (res.data.mainStpType.id === 2) {
                // правительства
                this.konvertStatistics.newRegions[1].acceptedStud--;
                this.konvertStatistics.newRegions[1].canceledStud++;
              } else if (res.data.mainStpType.id === 3) {
                // президента и правительства
                this.konvertStatistics.newRegions[2].acceptedStud--;
                this.konvertStatistics.newRegions[2].canceledStud++;
              }
            }
          }
          console.log('unblockRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async clickOnRow(e) {
      await this.initFakeUser({id: e.org.owner.id, name: e.org.owner.name, admin: true});
      await this.$router.push('/info');
      await this.$router.push('/main-2024/applicants');
    },
  },
};
</script>

<style lang="scss">
.regKonvert__blocked {
  background-image: url(../../../../../../assets/icons/status-blocked-red.svg);
  height: 21px;
  width: 17px;
}

.regKonvert__opened {
  background-image: url(../../../../../../assets/icons/status-opened-green.svg);
  height: 22px;
  width: 23px;
}
</style>
